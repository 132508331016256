// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require('@fortawesome/fontawesome-free');
require('jquery');

// Global Theme Bundle
require('../../../vendor/metronic/plugins/global/plugins.bundle');
require('../../../vendor/metronic/js/scripts.bundle');
require('../../../vendor/metronic/plugins/custom/datatables/datatables.bundle');


import '../stylesheets/application'
import Litepicker from 'litepicker'
import CodeMirror from 'codemirror'
import 'select2'
import 'jquery'
import initNestedForm from 'utils/nested_forms'

import initHiddenEmails from 'utils/hidden_email'
initHiddenEmails()
// Init code editor
window.codeEditors = [];
jQuery('textarea.editor').each(function (i, el) {
  var editor = CodeMirror.fromTextArea(el, {
    lineNumbers: true,
    theme: 'ambiance',
    mode: 'xml'
  });
  window.codeEditors.push(editor);
});

// Initialize all date range pickers
// Note from MK: I've renamed class `daterangepicker` to `daterange` due to strange hardcoded CSS for `daterangepicker`
// which positions picker absolute position and in wrong place. So I just avoid using `daterangepicker` class.
document.addEventListener('DOMContentLoaded', () => {
  let pickers = document.getElementsByClassName('daterange')
  Array.from(pickers).forEach((picker) => {
    let lPicker = new Litepicker(
      {
        element: picker,
        singleMode: picker.classList.contains('no__daterange'),
        format: 'DD-MM-YYYY'
      }
    )

    if (picker.dataset['range']) {
      let range = picker.dataset['range'].split(' - ')
      lPicker.setDateRange(range[0], range[1])
    }
  })

  document.querySelectorAll('button[type=reset]').forEach((btn, i) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault()
      const form = btn.closest('form')

      form.querySelectorAll('input,textarea,select').forEach((el, i) => {
        if (el.tagName == 'INPUT' && el.type != 'submit' && el.type != 'radio') {
          el.value = ''
          el.setAttribute('value', '')
          el.innerHTML = ''
          if (el.dataset['default']) {
            el.value = el.dataset['default']
          }
        } else if (el.tagName == 'SELECT') {
          el.querySelectorAll('option').forEach((o, i) => {
            if (o.selected) o.selected = false
          })
          if (el.dataset['default']) {
            el.querySelector(`option[value=${el.dataset['default']}]`).selected = true
            el.dispatchEvent(new Event('change'))
          }
        } else if (el.type == 'radio') {
          // For label-like radios (i. e. range_by)
          if (el.dataset['default']) return
          el.checked = false

          const defaultValue = form.querySelector(`input[type=${el.type}][name='${el.name}'][data-default=true]`)
          const changeEvent = new Event('change')

          if (defaultValue) {
            defaultValue.checked = true
            defaultValue.dispatchEvent(changeEvent)
          } else {
            el.dispatchEvent(changeEvent)
          }
        }
      })

      form.submit()
    })
  })

  document.querySelectorAll('form.search input[type=radio]').forEach((i) => {
    i.addEventListener('change', (e) => {
      const target = e.target
      target
        .closest('form')
        .querySelectorAll('input[type=radio]')
        .forEach((el) => el.parentNode.classList.remove('active'))

      if (target.checked) target.parentNode.classList.add('active')
    })
  })

  document.querySelectorAll('.nested-form-template').forEach((tmpl) => {
    initNestedForm(tmpl)
  })

  if (document.getElementById('block-user-btn')) {
    document.getElementById('block-user-btn').addEventListener('click', (e) => {
      document.getElementById('new_contests_participant_block').submit()
    })
  }
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).ready(function () {
  $('.datetimepicker').datetimepicker()

  const ktTable = $('.kt-datatable');
  if(ktTable.length) {
    ktTable.KTDatatable({
      rows: {
        autoHide: false
      }
    })
  }

  const ktMultiSelect = $('.kt-select2');
  if(ktMultiSelect.length) {
    ktMultiSelect.select2({
      placeholder: "Select a state",
    });
  };

  $('.select2-container').width('100%');

  $('.br-datetimepicker__calendar').datepicker({
    //numberOfMonths: 2,
    todayHighlight: true,
    beforeShowDay(d) {
      const start = new Date();
      start.setHours(0,0,0,0)
      return d.getTime() >= start.getTime()
    }
  });

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  $('.br-datetimepicker__calendar').on('changeDate', function(e) {
    $('#mailer_bulk_mailing_scheduled_at').val(formatDate(e.date))
  });


  $('.bootstrap-select').selectpicker();
});
