const initHiddenEmails = () => {
 document.querySelectorAll('.hidden_email').forEach((email) => {
   email.addEventListener('click', (e) => {
     e.preventDefault()

     email.innerText = email.dataset['email']
     email.classList.add('decrypted')
     email.classList.remove('kt-link')
   })
 })
}


export default initHiddenEmails
