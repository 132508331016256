import 'jquery'

const bindHideByCheckbox = (el) => {
  el.addEventListener('change', (e) => {
    let parent = e.target.closest('.nested__form')
    if (parent) {
      parent.style.display = 'none'
    }
  })
}

const initNestedForm = (templateEl) => {
  const id = templateEl.id
  const button = document.getElementById(`add-${id}`)
  const index = templateEl.dataset.tmpl

  // Hide form by checkbox for persisted rows
  templateEl.closest('.form-group').querySelectorAll('input[type=checkbox]').forEach((cb) => {
    if (cb.id.match('_destroy')) {
      bindHideByCheckbox(cb)
    }
  })

  let clickedCount = document.getElementsByClassName(id).length

  button.addEventListener('click', (e) => {
    e.preventDefault()
    clickedCount++

    const dup = templateEl.cloneNode(true)
    dup.id = `${id}-${clickedCount}`
    templateEl.parentNode.insertBefore(dup, templateEl)

    dup.style.display = 'block'
    const re = /\[\d\]\[\]/

    dup.querySelectorAll('input,label,select').forEach((el) => {
      if (el.tagName === 'LABEL') {
        el.htmlFor = el.htmlFor.replace(re, `[${clickedCount}]`)
      } else if (el.tagName === 'INPUT') {
        el.id = el.id.replace(re, `[${clickedCount}]`)
        el.name = el.name.replace(re, `[${clickedCount}]`)
        el.disabled = false
        if (el.type === 'checkbox' && el.id.match('_destroy') ) {
          bindHideByCheckbox(el)
        }
      } else if (el.tagName === 'SELECT') {
        el.id = el.id.replace(re, `[${clickedCount}]`)
        el.name = el.name.replace(re, `[${clickedCount}]`)
        el.disabled = false
      }
    })

    $(`#${dup.id} .bootstrap-select2`).addClass('bootstrap-select')
    $(`#${dup.id} .bootstrap-select`).selectpicker('refresh')
  })
}

export default initNestedForm
